<template>
  <div>
    <svg
      id="Laag_1"
      style="enable-background: new 0 0 183 183"
      version="1.1"
      viewBox="0 0 183 183"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      y="0px"
    >
      <g id="icon_meatloverz" transform="translate(-1438 -2973)">
        <g id="icon_halal" transform="translate(0 413)">
          <rect
            :fill="background"
            height="183"
            id="Rectangle_193"
            width="183"
            x="1438"
            y="2560"
          />
        </g>
      </g>
      <g>
	<g>
		<path class="st0" d="M87.7,71.1c-2.2,1.7-4.4,3.4-6.7,5.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.2-1,0.1-1.3-0.3
			c-0.3-0.4-0.2-1,0.2-1.4c0.1-0.1,0.3-0.2,0.4-0.3c2.4-1.5,4.7-3,7.1-4.5c0.1-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.2,0,0.3,0.1
			c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0,0.2-0.1,0.3C87.9,70.9,87.8,71,87.7,71.1z"/>
		<path class="st0" d="M89.6,69.2c0,0,0,0.1,0,0.1c-0.4,0.3-0.9,0.6-1.3,0.9c0,0-0.1,0-0.2,0C88.1,70.2,88,70.1,88,70
			c-0.1-0.1,0-0.2,0-0.3c0.4-0.3,0.8-0.6,1.2-0.8C89.3,68.9,89.6,69.1,89.6,69.2z"/>
		<path class="st0" d="M93.4,66.5C93.4,66.5,93.3,66.5,93.4,66.5C93.3,66.5,93.3,66.5,93.4,66.5C93.3,66.5,93.3,66.5,93.4,66.5
			C93.4,66.5,93.4,66.5,93.4,66.5C93.4,66.5,93.4,66.5,93.4,66.5L93.4,66.5C93.4,66.5,93.4,66.5,93.4,66.5z"/>
		<path class="st0" d="M93.8,66.4c-0.1,0.1-0.3,0.1-0.4,0.1c0,0,0,0,0-0.1c-0.2-0.3-0.2-0.3,0-0.4c0.8-0.6,1.6-1.1,2.4-1.7
			c0.2-0.1,0.4-0.1,0.4,0.1c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1C95.3,65.3,94.5,65.9,93.8,66.4z"/>
		<path class="st0" d="M95.5,70.4c-0.4-0.1-0.8-0.2-1.3-0.3c-0.2,0-0.4-0.1-0.5-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.4-0.6,0.8-1,1.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-3.1-2.1-6-4.3-8.7-6.9c-0.9-0.8-1.7-1.7-2.5-2.7c-0.5-0.6-0.9-1.2-1.3-1.9c-0.1-0.1-0.2-0.3-0.1-0.5
			c0.4-0.1,0.7,0.1,1,0.2c0.6,0.2,1.2,0.4,1.7,0.7c1,0.5,2,1.1,3,1.7c2.6,1.6,5,3.3,7.5,5c0.1,0,0.1,0.1,0.1,0.1
			c0.1,0,0.2,0,0.3,0.1c-0.1,0-0.1,0.1-0.1,0.1c0.4,0.3,0.8,0.5,1.2,0.8c0.3,0.2,0.3,0.2,0.1,0.6c0.1,0,0.1,0,0.2,0
			c0.1-0.2,0.3-0.3,0.5-0.1c0.1,0.1,0.2,0.2,0.4,0.2c2.8,1.9,5.5,3.9,8.3,5.8c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.2,0.3,0.5,0.2,0.8
			c-0.3,0.8-1.4,1.2-2.1,0.4c-0.2-0.2-0.4-0.5-0.6-0.7c-0.4-0.5-1-0.8-1.6-1c-1.2-0.2-2.1-0.9-2.9-1.9c-0.1-0.2-0.3-0.3-0.4-0.5
			C96.6,70.9,96.1,70.6,95.5,70.4z"/>
		<path class="st0" d="M95.9,63.5c0-0.3,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.5,0.6-0.7c0.5-0.4,0.9-0.7,1.4-1c1.3-0.9,2.6-1.9,3.9-2.9
			c0.1,0,0.1-0.1,0.2-0.1c0.1-0.2,0.3-0.3,0.5-0.4c0-0.1,0.1-0.1,0.1,0c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.3-0.3,0.3
			c-0.4,0.6-0.9,1-1.4,1.5c-0.6,0.6-1.3,1.2-2,1.8c-0.3,0.3-0.6,0.6-0.8,0.9c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1,0.1,0.3,0.2,0.3
			c0.1,0,0.3,0,0.4-0.1c0.4-0.1,0.8-0.3,1.1-0.5c0.8-0.4,1.6-0.8,2.4-1.2c0.7-0.3,1.3-0.7,2.1-0.8c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0
			c0,0.2-0.1,0.2-0.2,0.2c-1.5,0.9-3,1.9-4.5,2.9c-0.5,0.3-0.9,0.6-1.4,0.9c-0.3,0.2-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.1-0.7,0
			C96.4,64.6,96,64.2,95.9,63.5z"/>
		<path class="st1" d="M94.5,68c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
			c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
			c0,0,0,0,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c-0.3,0.4-0.6,0.8-1,1.3c-0.1-0.1-0.2-0.1-0.3-0.2
			c-3.1-2.1-6-4.3-8.7-6.9c-0.9-0.8-1.7-1.7-2.5-2.7c-0.5-0.6-0.9-1.2-1.3-1.9c-0.1-0.1-0.2-0.3-0.1-0.5c0.4-0.1,0.7,0.1,1,0.2
			c0.6,0.2,1.2,0.4,1.7,0.7c1,0.5,2,1.1,3,1.7c2.6,1.6,5,3.3,7.5,5c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0.2,0.1,0.3,0.2
			c0.4,0.3,0.8,0.5,1.2,0.8C94.8,67.6,94.8,67.6,94.5,68z"/>
		<path class="st1" d="M104.5,75.1c-0.3,0.8-1.4,1.2-2.1,0.4c-0.2-0.2-0.4-0.5-0.6-0.7c-0.4-0.5-1-0.8-1.6-1
			c-1.2-0.2-2.1-0.9-2.9-1.9c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3-0.6-0.9-0.9-1.5-1.1c-0.4-0.1-0.8-0.2-1.3-0.3c-0.2,0-0.4-0.1-0.5-0.3
			c-0.2-0.3,0-0.5,0.2-0.7c0,0,0-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0-0.1,0-0.1
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.2,0.3-0.3,0.5-0.1
			c0.1,0.1,0.2,0.2,0.4,0.2c2.8,1.9,5.5,3.9,8.3,5.8c0.1,0.1,0.3,0.2,0.4,0.3C104.6,74.5,104.6,74.8,104.5,75.1z"/>
		<path class="st2" d="M88,70.8c-0.1,0.1-0.2,0.2-0.3,0.3c-2.2,1.7-4.4,3.4-6.7,5.2c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.5,0.2-1,0.1-1.3-0.3c-0.3-0.4-0.2-1,0.2-1.4c0.1-0.1,0.3-0.2,0.4-0.3c2.4-1.5,4.7-3,7.1-4.5c0.1-0.1,0.3-0.2,0.4-0.2
			c0.1-0.1,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.3C88.1,70.6,88.1,70.7,88,70.8z"/>
		<path class="st3" d="M104.7,60.5c0,0.2-0.1,0.2-0.2,0.2c-1.5,0.9-3,1.9-4.5,2.9c-0.5,0.3-0.9,0.6-1.4,0.9
			c-0.3,0.2-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.1-0.7,0c-0.6-0.3-0.9-0.8-1-1.4c0-0.3,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.5,0.6-0.7
			c0.5-0.4,0.9-0.7,1.4-1c1.3-0.9,2.6-1.9,3.9-2.9c0.1,0,0.1-0.1,0.2-0.1c0.2,0,0.2,0,0.2,0.2c-0.4,0.6-0.9,1-1.4,1.5
			c-0.6,0.6-1.3,1.2-2,1.8c-0.3,0.3-0.6,0.6-0.8,0.9c-0.1,0.1-0.1,0.2-0.2,0.4c-0.1,0.1,0.1,0.3,0.2,0.3c0.1,0,0.3,0,0.4-0.1
			c0.4-0.1,0.8-0.3,1.1-0.5c0.8-0.4,1.6-0.8,2.4-1.2c0.7-0.3,1.3-0.7,2.1-0.8C104.4,60.5,104.5,60.5,104.7,60.5
			C104.6,60.5,104.6,60.5,104.7,60.5z"/>
		<polygon class="st4" points="93.4,66.5 93.4,66.5 93.4,66.5 93.4,66.5 93.4,66.5 93.4,66.5 93.4,66.5 		"/>
		<path class="st4" d="M96.2,64.7c0,0-0.1,0.1-0.1,0.1c-0.8,0.5-1.5,1.1-2.3,1.6c-0.1,0.1-0.3,0.1-0.4,0.1c0,0,0,0,0-0.1
			c-0.2-0.3-0.2-0.3,0-0.4c0.8-0.6,1.6-1.1,2.4-1.7c0.2-0.1,0.4-0.1,0.4,0.1C96.2,64.6,96.2,64.6,96.2,64.7z"/>
		<path class="st5" d="M89.6,69.3c-0.4,0.3-0.9,0.6-1.3,0.9c0,0-0.1,0-0.2,0C88.1,70.2,88,70.1,88,70c-0.1-0.1,0-0.2,0-0.3
			c0.4-0.3,0.8-0.6,1.2-0.8c0.1-0.1,0.4,0.1,0.4,0.3C89.6,69.2,89.6,69.3,89.6,69.3z"/>
		<path class="st6" d="M94,69.1c-0.1,0.2-0.3,0.4-0.3,0.7c-0.1,0-0.2-0.1-0.3-0.1c0-0.1,0-0.1,0-0.2c0.1-0.1,0.1-0.2,0.2-0.3
			c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2l0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
			C94.1,68.9,94.1,69,94,69.1z"/>
		<path class="st7" d="M102.7,57.9c-0.1,0.1-0.1,0.3-0.3,0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0.1-0.2,0.3-0.3,0.5-0.4
			C102.8,57.8,102.8,57.9,102.7,57.9z"/>
		<path class="st8" d="M94.7,68c0,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.1-0.1
			c0.1-0.1,0.1-0.2,0.2-0.3C94.6,68,94.7,68,94.7,68z"/>
		<path class="st9" d="M102.7,57.9c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.1,0.1,0C102.9,57.8,102.8,57.9,102.7,57.9z"/>
		<path class="st4" d="M93,66.4c0.1,0,0.2,0,0.3,0.1c-0.1,0-0.1,0.1-0.1,0.1C93.2,66.6,93.1,66.6,93,66.4z"/>
		<path class="st4" d="M93.4,66.5C93.4,66.5,93.4,66.5,93.4,66.5C93.4,66.5,93.3,66.5,93.4,66.5C93.3,66.5,93.3,66.5,93.4,66.5
			C93.3,66.5,93.3,66.5,93.4,66.5C93.4,66.5,93.4,66.5,93.4,66.5C93.4,66.5,93.4,66.5,93.4,66.5z"/>
		<path class="st5" d="M87.9,70.2c-0.1,0-0.2-0.1-0.2-0.2C87.8,70,87.9,70.1,87.9,70.2z"/>
		<path class="st10" d="M82.2,61.3C82.2,61.2,82.2,61.2,82.2,61.3c-0.1-0.3,0-0.3,0.1-0.3l0,0c0.1,0,0.1,0.1,0.1,0.1l0,0
			c0,0,0.1,0.1,0.2,0.1l0,0c0,0.1,0.1,0.1,0.2,0.1c0.2,0.2,0.5,0.4,0.7,0.6c1.9,1.7,3.7,3.3,5.6,5c0.1,0.1,0.2,0.2,0.3,0.3
			c0,0.1,0,0.2-0.2,0.2c-0.5-0.4-1.1-0.8-1.6-1.2c-1.7-1.3-3.3-2.7-4.7-4.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.1-0.1-0.1-0.1l0,0
			c-0.1,0-0.1-0.1-0.1-0.1l0,0C82.3,61.4,82.3,61.3,82.2,61.3z"/>
		<path class="st11" d="M89.2,67.5c0.1-0.1,0.1-0.1,0.2-0.2c0.3,0.2,0.6,0.5,0.9,0.7c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0.1
			C89.8,68,89.5,67.8,89.2,67.5z"/>
		<path class="st12" d="M82.3,61c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3-0.2-0.5-0.5-0.7-0.8c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
			C81.8,60.4,82.1,60.7,82.3,61z"/>
		<path class="st13" d="M94,69.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1,0-0.1c0,0,0,0,0.1,0c0.1-0.1,0.3-0.3,0.4-0.4l0.1,0
			c0,0,0,0.1,0.1,0.1C94.4,68.7,94.2,68.9,94,69.1z"/>
		<path class="st14" d="M90.9,68.7c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1,0-0.1,0-0.2c0.2,0.1,0.3,0.2,0.4,0.4
			C90.9,68.6,90.9,68.7,90.9,68.7C90.9,68.7,90.9,68.7,90.9,68.7z"/>
		<path class="st15" d="M91.1,68.8c0.1,0,0.2,0.1,0.3,0.3c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2
			C91.1,68.9,91.1,68.9,91.1,68.8C91.1,68.8,91.1,68.8,91.1,68.8z"/>
		<path class="st16" d="M90.4,68.2c0,0.1,0,0.1,0,0.2c-0.1,0-0.1-0.1-0.2-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
			C90.3,68.1,90.4,68.1,90.4,68.2z"/>
		<path class="st1" d="M93.6,69.2c0,0.1-0.1,0.2-0.2,0.3C93.5,69.4,93.5,69.2,93.6,69.2z"/>
		<path class="st1" d="M93.9,68.8c0,0,0,0.1,0,0.1c-0.1,0-0.1,0.1-0.2,0.1C93.7,68.9,93.8,68.8,93.9,68.8z"/>
		<path class="st17" d="M81.5,60.3c0,0,0,0.1,0,0.1c-0.1-0.1-0.2-0.1-0.2-0.3l0,0C81.4,60.1,81.5,60.2,81.5,60.3z"/>
		<path class="st18" d="M81.3,60.1c0,0-0.1,0-0.1-0.1C81.3,60,81.3,60.1,81.3,60.1C81.3,60.1,81.3,60.1,81.3,60.1z"/>
		<path class="st12" d="M82.5,61.1c-0.1,0-0.1,0-0.1-0.1C82.4,61,82.5,61,82.5,61.1z"/>
		<path class="st12" d="M82.6,61.3c-0.1,0-0.1-0.1-0.2-0.1C82.6,61.1,82.6,61.2,82.6,61.3z"/>
		<path class="st12" d="M82.2,61.3c0.1,0,0.1,0,0.1,0.1C82.3,61.4,82.2,61.3,82.2,61.3z"/>
		<path class="st12" d="M82.8,61.4c-0.1,0-0.1-0.1-0.2-0.1C82.7,61.3,82.8,61.3,82.8,61.4z"/>
		<path class="st12" d="M82.4,61.4c0.1,0,0.1,0.1,0.1,0.1C82.4,61.5,82.4,61.5,82.4,61.4z"/>
		<path class="st12" d="M82.5,61.5c0.1,0,0.1,0.1,0.1,0.1C82.6,61.7,82.5,61.6,82.5,61.5z"/>
		<path class="st19" d="M90.2,68.1c0,0,0,0.1,0,0.1c0,0-0.1-0.1-0.1-0.1C90.2,68.1,90.2,68.1,90.2,68.1z"/>
		<path class="st1" d="M93.7,69.1c0,0.1,0,0.2-0.1,0.2C93.6,69.1,93.7,69.1,93.7,69.1z"/>
		<path class="st20" d="M91.1,68.8c0,0.1,0,0.1,0,0.2c-0.1,0-0.2-0.1-0.1-0.2l0,0C91,68.8,91.1,68.8,91.1,68.8z"/>
		<path class="st21" d="M90.9,68.7c0,0,0-0.1,0-0.1c0.1,0,0.2,0.1,0.1,0.2c0,0,0,0,0,0C91,68.8,90.9,68.7,90.9,68.7z"/>
		<path class="st22" d="M102.9,74.8c-0.1,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.4,0.2,0.3,0.4
			C103.3,74.7,103.1,74.8,102.9,74.8z"/>
		<path class="st23" d="M96.1,69.4c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.3
			C96,69.1,96.1,69.3,96.1,69.4z"/>
		<path class="st24" d="M99.7,72c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3C99.6,71.7,99.7,71.8,99.7,72z
			"/>
		<path class="st25" d="M94.4,68.5c0,0-0.1,0-0.1-0.1c0,0,0.1,0,0.1,0C94.5,68.4,94.5,68.4,94.4,68.5z"/>
		<path class="st26" d="M82.6,74.4c-0.7,0.5-1.4,1.1-2,1.6c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.8-0.1-0.8-0.6c0-0.3,0.1-0.4,0.3-0.2
			c0.3,0.2,0.6,0.2,0.9,0c0.4-0.2,0.7-0.5,1.1-0.7c0.2-0.1,0.4-0.3,0.6-0.4C82.6,74.2,82.7,74.3,82.6,74.4z"/>
		<path class="st22" d="M82.6,74.4c-0.1-0.1-0.1-0.1-0.2-0.2c0.3-0.3,0.7-0.5,1-0.7c0.2-0.1,0.2,0,0.2,0.2
			C83.3,73.9,83,74.2,82.6,74.4z"/>
		<path class="st27" d="M83.6,73.7c-0.1-0.1-0.1-0.1-0.2-0.2c0.2-0.2,0.4-0.3,0.6-0.4c0,0,0,0,0.1,0.1c0,0.1,0,0.1-0.1,0.2
			C84,73.5,83.8,73.6,83.6,73.7z"/>
		<path class="st28" d="M84.7,72.9c-0.1,0.2-0.3,0.3-0.4,0.3c0,0-0.1-0.1-0.1-0.1l0-0.1c0.2-0.2,0.4-0.3,0.6-0.4
			C84.7,72.7,84.7,72.8,84.7,72.9z"/>
		<path class="st29" d="M85.8,72c-0.1,0.2-0.3,0.3-0.5,0.4c0,0-0.1-0.1,0-0.1c0,0,0-0.1,0-0.1C85.5,72,85.6,71.9,85.8,72L85.8,72z"
			/>
		<path class="st30" d="M85.9,71.8c0.1-0.2,0.2-0.3,0.4-0.3l0,0c-0.1,0.2-0.2,0.3-0.4,0.4C85.9,71.9,85.9,71.8,85.9,71.8z"/>
		<path class="st31" d="M86.5,71.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
			C86.5,71.5,86.5,71.4,86.5,71.4C86.5,71.4,86.5,71.4,86.5,71.4z"/>
		<path class="st32" d="M85.2,72.4c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.3-0.2
			C85.3,72.3,85.3,72.4,85.2,72.4z"/>
		<path class="st33" d="M84.9,72.5c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1-0.1,0-0.1c0,0,0,0,0,0
			C84.8,72.5,84.9,72.5,84.9,72.5z"/>
		<path class="st34" d="M84.8,72.6c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0-0.1,0-0.1,0-0.2C84.7,72.6,84.7,72.6,84.8,72.6z"/>
		<path class="st35" d="M85.2,72.4c0-0.1,0-0.1,0-0.2c0,0,0.1-0.1,0.1-0.1c0,0.1,0,0.1,0,0.2C85.3,72.4,85.3,72.4,85.2,72.4z"/>
		<path class="st36" d="M85.9,71.8c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0C85.8,71.8,85.9,71.8,85.9,71.8z"/>
		<path class="st37" d="M86.5,71.4c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0s0,0,0,0C86.4,71.4,86.4,71.4,86.5,71.4z"/>
		<path class="st38" d="M84.1,73.1c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1C84.1,73.3,84.1,73.2,84.1,73.1z"/>
		<path class="st39" d="M99.1,63.7c-0.4,0.3-0.8,0.5-1.2,0.7c-0.7,0.3-1.4-0.2-1.4-1c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4-0.1,0.6,0
			c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.6,0.4,0.9,0.4c0.1,0,0.1,0,0.2,0C98.9,63.5,99.1,63.4,99.1,63.7z"/>
		<path class="st40" d="M99.1,63.7c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.2,0
			C99.3,63.6,99.2,63.6,99.1,63.7z"/>
		<path class="st41" d="M99.8,63.3c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.1
			C99.8,63.2,99.9,63.2,99.8,63.3C99.8,63.3,99.8,63.3,99.8,63.3z"/>
		<path class="st42" d="M99.8,63.3c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2,0c0,0,0,0.1-0.1,0.1C99.9,63.3,99.8,63.3,99.8,63.3z"/>
		<path class="st43" d="M99.5,63.3c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0s-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0C99.3,63.3,99.4,63.3,99.5,63.3z"
			/>
	</g>
	<path class="st4" d="M38,88.2L38,88.2C38,88.2,38,88.2,38,88.2C38,88.2,38,88.2,38,88.2z M41.2,101.7c0-6.5,0-13.1,0-19.6
		c0-0.1,0-0.3,0-0.4c0-0.3,0-0.3-0.3-0.3c-1.3,0-2.6,0-4,0c-0.3,0-0.4,0-0.4,0.4c-0.2,1-0.3,2.1-0.5,3.1c-0.3,1.7-0.5,3.5-0.8,5.2
		c-0.3,1.8-0.5,3.6-0.8,5.4c0,0.2,0.1,0.4-0.1,0.5c-0.1,0.1-0.2-0.1-0.2-0.5c-0.2-1.4-0.5-2.7-0.7-4.1c-0.4-2.2-0.7-4.4-1.1-6.6
		c-0.2-1-0.3-2-0.5-3.1c-0.1-0.3-0.1-0.3-0.4-0.3c-1.2,0-2.3,0-3.5,0c-0.9,0-0.8-0.1-0.8,0.8c0,6.5,0,13,0,19.5c0,0.2,0,0.3,0,0.5
		c0,0.3,0,0.3,0.3,0.3c0.8,0,1.5,0,2.3,0c0.1,0,0.2,0,0.3-0.1c0-0.2,0-0.4,0-0.6c0-4.2,0-8.5,0-12.7c0-0.3,0-0.5,0-0.8
		c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.3c0,0.3,0.2,0.7,0.1,1c0.1,0.3,0.1,0.6,0.2,0.8
		c0.2,1.3,0.4,2.6,0.7,3.9c0.3,1.9,0.6,3.8,0.9,5.7c0.1,0.8,0.3,1.7,0.4,2.5c0,0.2,0.1,0.3,0.3,0.3c0.9,0,1.8,0,2.7,0
		c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1.1,0.3-2.3,0.5-3.4c0.3-1.7,0.5-3.5,0.8-5.2c0.2-1.2,0.4-2.5,0.6-3.7
		c-0.1-0.4,0.1-0.8,0.2-1.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3
		c0,0.2,0,0.5,0,0.7c0,4.3,0,8.6,0,12.9c0,0.2,0,0.3,0,0.5c0.1,0.1,0.3,0.1,0.4,0.1c0.6,0,1.3,0,1.9,0
		C41.3,102.5,41.2,102.6,41.2,101.7z M53.7,99.5c-0.2,0-0.3,0-0.5,0c-1.4,0-2.8,0-4.2,0c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.2-0.2
		c0-0.2,0-0.4,0-0.6c0-1.4,0-2.9,0-4.3c0-0.3-0.1-0.7,0-1c0.3-0.2,0.7-0.1,1.1-0.1c1,0,2.1,0,3.1,0c0.3,0,0.4,0,0.4-0.4
		c0-0.8,0-1.5,0-2.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3,0-0.5,0c-1.1,0-2.1,0-3.2,0c-0.2,0-0.3,0-0.5,0
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4c0-1.6,0-3.2,0-4.8c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.4,0,0.6,0
		c1.5,0,3,0,4.5,0c0.2,0,0.3-0.1,0.3-0.3c0-0.8,0-1.6,0-2.5c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-2.5,0-5,0-7.6,0
		c-0.8,0-0.7-0.1-0.7,0.7c0,3.3,0,6.5,0,9.8v9.8c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3,0,0.5,0c2.5,0,5,0,7.5,0
		c0.1,0,0.3,0,0.4,0c0.3,0,0.4,0,0.4-0.3c0-0.8,0-1.6,0-2.4C54,99.5,54,99.5,53.7,99.5z M59.1,110.3c0-0.2-0.1-0.2-0.3-0.2
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.4,0-0.4,0.4c0,0.7,0,1.5,0,2.2c0,0.7,0,1.4,0,2.2c0,0.5,0,0.6,0.6,0.5c0,0,0.1,0,0.1,0
		c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3c0-1.5,0-2.9,0-4.4C59.1,110.4,59.1,110.3,59.1,110.3z M62.5,110c-0.7,0-1.4,0-2.1,0
		c-0.1,0-0.2,0-0.2,0.2c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.4,0c0.3,0,0.4,0.1,0.4,0.4c0,0.6,0,1.2,0,1.9h0
		c0,0.7,0,1.3,0,2c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.1,0,0.2,0c0.3,0,0.3,0,0.4-0.3c0-0.2,0-0.3,0-0.5c0-1,0-1.9,0-2.9
		c0-0.3-0.1-0.6,0.1-0.8c0.1-0.2,0.4,0,0.6-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3C62.8,110.1,62.7,110,62.5,110z M64.5,110
		c-0.1,0-0.1,0-0.2,0c-0.4,0-0.5,0-0.5,0.4c0,0.2,0,0.3,0.2,0.4c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2
		c0.1,0,0.2,0,0.2-0.1c0.3-0.4,0.4-0.8,0.4-1.3C64.7,110.1,64.6,110,64.5,110z M68,102.5c-0.5,0-1,0-1.4,0v0c-0.5,0-0.9,0-1.4,0
		c-0.3,0-0.3-0.1-0.4-0.3c-0.1-0.7-0.2-1.4-0.3-2.1c-0.1-0.4-0.1-0.8-0.2-1.1c0-0.3-0.1-0.3-0.4-0.3c-1.1,0-2.3,0-3.4,0
		c-0.3,0-0.3,0-0.4,0.4c-0.2,1-0.3,2.1-0.5,3.1c0,0.3-0.1,0.3-0.4,0.3c-0.8,0-1.6,0-2.5,0c-0.2,0-0.3-0.1-0.3-0.3
		c0.3-1.8,0.6-3.6,0.9-5.4c0.3-1.7,0.5-3.3,0.8-5c0.3-2,0.6-3.9,0.9-5.9c0.2-1.3,0.4-2.7,0.6-4c0-0.1,0-0.2,0.1-0.4
		c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0h3.9c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.3,0.3c0.1,0.6,0.2,1.2,0.3,1.8
		c0.6,3.6,1.2,7.2,1.7,10.8c0.4,2.6,0.8,5.2,1.2,7.8c0,0.1,0,0.1,0,0.2C68.2,102.4,68.1,102.5,68,102.5z M63.7,94.9
		c-0.3-1.8-0.5-3.6-0.8-5.3c-0.2-1.3-0.4-2.5-0.6-3.8c0,0-0.1,0-0.1,0c0,0.2-0.1,0.5-0.1,0.7c-0.3,1.8-0.5,3.6-0.8,5.3
		C61,92.9,60.9,94,60.7,95c0,0.2-0.1,0.5,0,0.7c1,0.1,2.5,0.1,3,0C63.8,95.4,63.7,95.2,63.7,94.9z M67.4,112.4
		c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.2-0.2-0.5-0.2-0.7c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.3,0.1,0.4,0.3c0,0.1,0,0.1,0,0.2
		c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0-0.8-0.4-1.3-1.3-1.3c-0.7,0-1.1,0.4-1.2,1.1
		c-0.1,0.5,0.1,1,0.4,1.4c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.2,0.2,0.4,0.2,0.7c0,0.2-0.1,0.4-0.3,0.4
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.1-0.6,0-0.6,0.5c0,0.1,0,0.2,0,0.3
		c0.1,0.6,0.6,0.9,1.2,0.9c0.7,0,1.1-0.3,1.2-0.9c0.1-0.6,0-1.1-0.4-1.6C67.8,112.7,67.6,112.6,67.4,112.4z M73.5,110
		c-0.6,0-1.2,0-1.8,0c-0.4,0-0.4,0-0.4,0.4c0,0.3,0,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.3,0.3c0,0.6,0,1.3,0,1.9h0
		c0,0.6,0,1.2,0,1.7c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1c0.2,0,0.4,0,0.6,0c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.3,0-0.4
		c0-1.1,0-2.1,0-3.2c0-0.2-0.1-0.5,0.1-0.7c0.2-0.2,0.4-0.1,0.6-0.1c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1
		C73.8,110.1,73.8,110,73.5,110z M53.4,112.8c-0.2,0-0.4,0-0.6,0c-11.6,0-23.2,0-34.8,0c-0.9,0-0.8,0.1-0.8-0.8
		c0-13.6,0-27.1,0-40.7c0-0.9-0.1-0.8,0.8-0.8c7.7,0,15.4,0,23.2,0c11.3,0,22.5,0,33.8,0c0.2,0,0.5,0,0.7,0c0.1,0,0.2-0.1,0.2-0.2
		c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.5,0-0.7,0c-9.5,0-19.1,0-28.6,0s-19.1,0-28.6,0c-1,0-0.9-0.1-0.9,0.9c0,13.8,0,27.7,0,41.5
		c0,0.2,0,0.3,0,0.5c0,0.3,0.1,0.3,0.3,0.4c0.1,0,0.3,0,0.4,0h35.5c0.2,0,0.3,0,0.5,0c0.1,0,0.2-0.1,0.2-0.2
		C53.6,112.9,53.5,112.8,53.4,112.8z M75.8,110.5c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.3,0-0.4,0
		c-0.2,0-0.3,0.1-0.3,0.3v2.4h0c0,0.7,0,1.4,0,2.1c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1c0.2,0,0.4,0,0.6,0c0,0,0.1-0.1,0.1-0.2
		c0-0.1,0-0.2,0-0.3C75.8,113.4,75.8,111.9,75.8,110.5z M77.3,84.4c0.2,0,0.4,0,0.6,0c0.7,0,1.5,0,2.2,0c0.2,0,0.3-0.1,0.3-0.3
		c0-0.9,0-1.7,0-2.6c0,0,0-0.1-0.1-0.1c-0.2,0-0.3,0-0.5,0c-3,0-6.1,0-9.1,0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0.1-0.3,0.3
		c0,0.8,0,1.6,0,2.5c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.3,0,0.4,0c0.8,0,1.7,0,2.5,0c0.3,0,0.3,0,0.3,0.3
		c0,0.2,0,0.4,0,0.6v16.3c0,0.2,0,0.3,0,0.5c0,0.4,0,0.4,0.5,0.4c0.7,0,1.5,0,2.2,0c0.6,0,0.6,0,0.6-0.6c0-0.1,0-0.3,0-0.4V85.3
		c0-0.2,0-0.3,0-0.5C76.9,84.4,76.9,84.4,77.3,84.4z M80.6,111c0-1.1,0.2-1-1-0.9c0,0-0.1,0-0.2,0.1c-0.2,1-0.3,2.1-0.5,3.1
		c-0.3-0.5-0.3-1-0.4-1.5c-0.1-0.5-0.2-1.1-0.3-1.6c-0.3-0.1-0.6,0-1,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0,0.3c0,1.4,0,2.9,0,4.3
		c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0.1,0.1c0.2,0.1,0.3,0.1,0.5,0c0.1,0,0.1-0.2,0.1-0.3c0-0.7,0-1.4,0-2.1c0,0,0.1,0,0.1,0
		c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.5,0.2,1,0.3,1.6c0.1,0.3,0.1,0.3,0.4,0.3c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.3-0.3
		c0.1-0.7,0.2-1.4,0.3-2.1c0.2,0.1,0.2,0.2,0.2,0.4c0,0.5,0,1,0,1.5c0,0.2,0,0.3,0.1,0.5c0.2,0.2,0.4,0.1,0.7,0.1
		c0.1-0.2,0.1-0.4,0.1-0.7C80.6,113.4,80.6,112.2,80.6,111z M84.1,114.5c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.2-1-0.1
		c-0.2-0.3,0-0.6-0.1-1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0-0.2,0-0.3
		c0-0.2-0.1-0.3-0.3-0.3c-0.3,0-0.6,0.1-0.8-0.1c-0.2-0.2,0-0.6-0.1-0.8c0,0,0-0.1,0-0.1c0-0.3,0-0.3,0.3-0.3c0.3,0,0.6,0,0.9,0
		c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2c0-0.3,0-0.3-0.3-0.3c-0.6,0-1.1,0-1.7,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.8,0,1.5,0,2.3
		c0,0.8,0,1.5,0,2.3c0,0.3,0,0.4,0.3,0.4c0.6,0,1.1,0,1.7,0c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2,0-0.3
		C84.2,114.6,84.2,114.6,84.1,114.5z M89.4,110c-0.6,0-1.2,0-1.7,0c-0.5,0-0.4,0.1-0.4,0.4c0,0.3,0,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0
		c0.3,0,0.3,0.1,0.3,0.4c0,0.6,0,1.2,0,1.9h0c0,0.6,0,1.1,0,1.7c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.4,0
		c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.3,0-0.5c0-1.1,0-2.2,0-3.2c0-0.5,0-0.6,0.5-0.6c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.3-0.3
		C89.8,110.1,89.8,110,89.4,110z M92,99.6c0-0.1-0.1-0.1-0.1-0.1c-0.2,0-0.4,0-0.6,0c-1.4,0-2.9,0-4.3,0c-0.4,0-0.4,0-0.4-0.4
		c0-0.2,0-0.3,0-0.5V82.3c0-0.2,0-0.3,0-0.5c0-0.5,0-0.5-0.5-0.5c-0.8,0-1.6,0-2.5,0c-0.4,0-0.4,0-0.4,0.4c0,0.1,0,0.3,0,0.4v9.7
		c0,3.3,0,6.5,0,9.8c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4,0c2.5,0,5,0,7.4,0c0.1,0,0.3,0,0.4,0
		c0.2,0,0.3-0.1,0.3-0.3C92,101.4,92,100.5,92,99.6C92,99.7,92,99.6,92,99.6z M93.3,114.1c0,1-1,1.5-1.9,1.1
		c-0.3-0.2-0.5-0.4-0.6-0.8c0-0.2,0-0.4,0-0.6c0-0.8,0-1.6,0-2.4c0-0.1,0-0.1,0-0.2c0.1-1,0.8-1.5,1.8-1.2c0.4,0.2,0.7,0.5,0.7,1
		c0,0.4,0,0.9,0,1.3c0,0.1,0,0.2,0,0.4h0C93.3,113.2,93.3,113.7,93.3,114.1z M92.5,112.7L92.5,112.7c0-0.5,0-1.1,0-1.6
		c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.1-0.4,0.3c0,0.1,0,0.2,0,0.4c0,0.8,0,1.7,0,2.5c0,0.1,0,0.2,0,0.4c0,0.2,0.2,0.3,0.4,0.3
		c0.2,0,0.3-0.2,0.3-0.3c0-0.1,0-0.2,0-0.4C92.5,113.5,92.5,113.1,92.5,112.7z M99,114.6c-0.1,0.3-0.3,0.6-0.7,0.7
		c-0.5,0.1-1.1,0.1-1.6,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4c0-1.4,0-2.8,0-4.2c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.2-0.2
		c0.4,0,0.9,0,1.3,0c0.5,0,0.8,0.3,0.9,0.8c0.1,0.6,0.1,1.1-0.4,1.6C99.3,113.1,99.1,113.9,99,114.6z M97.4,111.4L97.4,111.4
		c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0.1,0.2c0.3,0.1,0.6-0.1,0.6-0.3c0.1-0.3,0.1-0.5,0-0.8c-0.1-0.2-0.3-0.3-0.6-0.3
		c-0.2,0-0.2,0.1-0.2,0.4C97.4,111.3,97.4,111.3,97.4,111.4z M98.2,113.3c0-0.3-0.2-0.4-0.6-0.4c-0.2,0-0.3,0.1-0.3,0.4
		c0,0.1,0,0.2,0,0.4c0,0,0,0,0,0c0,0.2,0,0.3,0,0.5c0,0.3,0.1,0.3,0.3,0.3c0.4,0,0.5-0.1,0.5-0.5C98.3,113.8,98.3,113.6,98.2,113.3z
		 M103,115.2c0,0,0,0.1-0.1,0.1c-0.2,0-0.4,0.1-0.6,0c-0.1,0-0.1-0.2-0.1-0.2c-0.1-0.2,0-0.5-0.2-0.6c-0.2-0.2-0.5-0.1-0.8-0.1
		c0,0-0.1,0-0.2,0.1c0,0.2-0.1,0.4-0.1,0.5c0,0.1,0,0.3-0.1,0.3c-0.2,0.1-0.4,0.1-0.6,0c-0.1,0-0.1-0.1-0.1-0.2
		c0.1-0.4,0.1-0.8,0.2-1.1c0.2-1.2,0.4-2.4,0.6-3.5c0.1-0.3,0.1-0.3,0.3-0.3c1.2,0,0.9-0.2,1.1,0.9c0.2,1.3,0.4,2.6,0.6,4
		C103,115,103,115.1,103,115.2z M101.9,113.3c-0.1-0.6-0.2-1.1-0.3-1.7c0,0-0.1,0-0.1,0c-0.2,0.6-0.2,1.2-0.3,1.8
		c0,0.2,0,0.3,0.2,0.3c0.1,0,0.1,0,0.2,0C101.9,113.6,102,113.6,101.9,113.3z M104.6,91.9v5.5c0,0.5,0,1.1-0.1,1.6
		c-0.5,2.1-1.8,3.3-3.9,3.6c-0.9,0.1-1.7,0.1-2.6-0.1c-1.5-0.4-2.6-1.3-3.1-2.8c-0.2-0.7-0.4-1.4-0.4-2.2c0-3.7,0-7.5,0-11.2
		c0-0.6,0.1-1.2,0.2-1.8c0.5-1.8,1.6-2.9,3.4-3.3c1-0.2,2.1-0.2,3.1,0.1c1.5,0.4,2.5,1.3,3,2.8c0.2,0.7,0.3,1.4,0.3,2.1
		C104.6,88.1,104.6,90,104.6,91.9z M101.3,91.9c0-1.7,0-3.5,0-5.2c0-0.3,0-0.7,0-1c-0.1-0.5-0.2-0.9-0.6-1.2c-1-0.7-2.5-0.1-2.7,1.1
		c-0.1,0.4-0.1,0.8-0.1,1.2c0,3.5,0,6.9,0,10.4c0,0.4,0,0.8,0.1,1.1c0.2,1.2,1.5,1.8,2.6,1.2c0.5-0.3,0.7-0.7,0.7-1.3
		c0-0.3,0-0.7,0-1C101.3,95.4,101.3,93.6,101.3,91.9z M106.6,115.3c-0.2,0.1-0.4,0.1-0.6,0c-0.1,0-0.1-0.1-0.1-0.2
		c0-0.3,0-0.6,0-0.9c0-0.2,0-0.4,0-0.6c0-0.2-0.2-0.4-0.4-0.4c-0.3,0-0.4,0-0.4,0.3c0,0.5,0,1.1,0,1.6c0,0.2-0.1,0.3-0.3,0.3
		c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4c0-1.4,0-2.8,0-4.3c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.1,0.1-0.1
		c0.5,0,1,0,1.5,0c0.4,0,0.7,0.3,0.7,0.7c0.1,0.7,0.2,1.4-0.3,2c0.4,0.4,0.5,0.8,0.4,1.3c0,0.3,0,0.5,0,0.8
		C106.7,115,106.8,115.2,106.6,115.3z M105.8,111.2c0-0.3-0.2-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.3,0.2c0,0.2,0,0.4,0,0.6h0
		c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0.1,0.2c0.3,0.1,0.6-0.1,0.7-0.4C105.9,111.7,105.9,111.5,105.8,111.2z M110.6,113.6
		c0,0.3,0,0.6-0.1,0.9c-0.1,0.4-0.3,0.6-0.7,0.8c-0.5,0.2-1.1,0.1-1.6,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4
		c0-1.4,0-2.8,0-4.2c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.2-0.2c0.5,0,1,0,1.4,0c0.4,0,0.8,0.3,0.8,0.8c0.1,0.6,0.1,1.2-0.4,1.6
		C110.5,112.8,110.6,113.2,110.6,113.6z M108.8,111.4L108.8,111.4c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.3,0.2c0.3,0,0.5-0.2,0.5-0.5
		c0-0.2,0-0.4,0-0.6c0-0.2-0.2-0.4-0.5-0.4c-0.2,0-0.2,0.1-0.2,0.4C108.8,111.2,108.8,111.3,108.8,111.4z M109.6,113.1
		c-0.1-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0,0.4h0c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.3,0.3
		c0.2,0,0.4,0.1,0.5-0.2C109.8,114,109.8,113.5,109.6,113.1z M113.9,114.5c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7,0.1-0.9-0.1
		c-0.2-0.2,0-0.6-0.1-0.9c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.4,0,0.6,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1
		c0-0.4,0-0.4-0.4-0.4c0,0-0.1,0-0.1,0c-0.7,0-0.6,0-0.6-0.6c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.3,0,0.7,0,1,0
		c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.6,0-1.1,0-1.7,0c-0.3,0-0.3,0.1-0.3,0.4c0,0.8,0,1.5,0,2.3h0
		c0,0.8,0,1.6,0,2.4c0,0.2,0.1,0.3,0.3,0.3c0.6,0,1.2,0,1.7,0c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2,0-0.3
		C114.1,114.6,114,114.6,113.9,114.5z M117.6,113.4c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0.1-0.3,0.2c0,0.2,0,0.4,0,0.6
		c0,0.2-0.2,0.3-0.4,0.4c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0-0.4c0-0.8,0-1.6,0-2.5c0-0.1,0-0.2,0-0.4c0-0.2,0.2-0.3,0.4-0.3
		c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.3,0,0.4c0,0.3,0.1,0.3,0.3,0.3c0.5,0,0.6,0,0.5-0.6c0-0.2,0-0.3-0.1-0.5
		c-0.1-0.4-0.4-0.7-0.8-0.7c-0.2,0-0.5,0-0.7,0c-0.4,0.1-0.6,0.3-0.8,0.7c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.4,0,0.9,0,1.3
		c0,0.4,0,0.9,0,1.3c0,0.2,0,0.4,0.1,0.6c0.1,0.4,0.4,0.7,0.8,0.8c0.5,0.1,1,0.1,1.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3
		c0.1-0.4,0.2-0.8,0.1-1.1C117.8,113.4,117.7,113.4,117.6,113.4z M118.5,81.3c-0.8,0-1.7,0-2.5,0c-0.3,0-0.3,0.1-0.4,0.3
		c-0.1,0.8-0.2,1.5-0.3,2.3c-0.3,1.8-0.5,3.6-0.8,5.3c-0.3,1.8-0.5,3.7-0.8,5.5c-0.1,0.9-0.3,1.8-0.4,2.7c0,0.1,0,0.2,0,0.3
		c0,0.1,0,0.2,0,0.3c0,0,0,0.1-0.1,0.1c0,0,0,0-0.1,0c-0.1-0.2-0.2-0.4-0.1-0.6c-0.2-1.1-0.3-2.3-0.5-3.4c-0.3-2.1-0.6-4.2-0.9-6.3
		c-0.3-2-0.6-4-0.9-6.1c0-0.3-0.1-0.3-0.4-0.3c-0.9,0-1.7,0-2.6,0c-0.1,0-0.3,0-0.4,0.1c0.1,0.6,0.2,1.3,0.3,1.9
		c0.3,1.8,0.5,3.5,0.8,5.3c0.3,1.7,0.5,3.4,0.8,5.1c0.3,2.1,0.6,4.1,1,6.2c0.1,0.8,0.2,1.5,0.4,2.3c0.1,0.3,0.1,0.4,0.4,0.4
		c1.4,0,2.8,0,4.3,0c0.3,0,0.4,0,0.4-0.4c0.1-0.7,0.2-1.4,0.3-2.1c0.3-1.7,0.5-3.4,0.8-5.1s0.5-3.4,0.8-5.1c0.3-1.8,0.5-3.5,0.8-5.3
		c0.2-1,0.3-2,0.5-3.1C118.6,81.4,118.5,81.3,118.5,81.3z M121.4,110c-0.1,0-0.2,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.1,0,0.2,0,0.4
		c0,1,0,2.1,0,3.1c0,0.2,0,0.4,0,0.6c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.4-0.3c0-0.2,0-0.4,0-0.6c0-1.1,0-2.2,0-3.2
		c0-0.5,0-0.5-0.5-0.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0,0.3c0,1.2,0,2.4,0,3.5c0,0.2,0,0.3,0,0.5
		c0.1,0.4,0.3,0.7,0.7,0.8c0.8,0.2,1.7,0,1.7-1.2c0-0.1,0-0.1,0-0.2c0-1.2,0-2.3,0-3.5C121.5,110.3,121.5,110.2,121.4,110z
		 M124.6,114.5c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.4,0-0.8,0-1.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5,0,0.7,0
		c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.4c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0.1-0.7-0.1
		c-0.2-0.2,0-0.5-0.1-0.7c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.3,0.3-0.3c0.3,0,0.6,0,0.9,0c0.2,0,0.2-0.1,0.2-0.3c0,0,0-0.1,0-0.1
		c0-0.3,0-0.3-0.3-0.3c-0.6,0-1.1,0-1.7,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.8,0,1.5,0,2.3c0,0.8,0,1.6,0,2.4c0,0.2,0,0.3,0.2,0.3
		c0.6,0,1.2,0,1.9,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1C125.1,114.6,125,114.5,124.6,114.5z M130.5,102.5c0.1,0,0.2-0.1,0.2-0.2
		c0-0.9,0-1.8,0-2.7c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4,0-0.6,0c-1.4,0-2.8,0-4.2,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3-0.1-0.3-0.3
		c0-0.2,0-0.3,0-0.5c0-1.3,0-2.6,0-3.9c0-0.5-0.1-1,0-1.4c0.5-0.2,1-0.1,1.5-0.1c0.8,0,1.7,0,2.5,0c0.5,0,0.5,0,0.5-0.5
		c0-0.7,0-1.3,0-2c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.5,0c-1,0-2.1,0-3.1,0c-0.2,0-0.3,0-0.5,0
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0-0.3,0-0.5c0-1.1,0-2.3,0-3.4c0-0.5,0-1,0-1.4c0-0.3,0-0.3,0.3-0.4c0.2,0,0.3,0,0.5,0
		c1.4,0,2.7,0,4.1,0c0.2,0,0.4,0,0.6,0c0.1,0,0.2-0.1,0.2-0.2c0-0.3,0-0.6,0-0.9c0-0.6,0-1.1,0-1.7c0-0.2-0.1-0.3-0.3-0.3
		c-0.1,0-0.1,0-0.2,0c-2.7,0-5.4,0-8.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0,0.6c0,3.2,0,6.5,0,9.7v9.7
		c0,0.2,0,0.4,0,0.6c0,0.3,0.1,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c2.6,0,5.2,0,7.9,0C130.2,102.5,130.3,102.5,130.5,102.5z M144.3,102.4
		c-0.1,0-0.1,0.1-0.2,0.1c-1,0-1.9,0-2.9,0c-0.3,0-0.3,0-0.4-0.3c-0.2-0.5-0.2-1-0.2-1.6c0-1.4,0-2.7,0-4.1c0-0.4,0-0.8-0.1-1.2
		c-0.2-0.9-0.8-1.3-1.6-1.4c0,0-0.1,0-0.1,0c-0.4,0-0.9,0-1.3,0c-0.2,0.4-0.1,0.8-0.1,1.3c0,2.2,0,4.3,0,6.5c0,0.2,0,0.4,0,0.6
		c0,0.1-0.1,0.2-0.2,0.2c-0.9,0-1.9,0-2.8,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.3,0-0.4V82c0-0.8-0.1-0.7,0.7-0.7c1.5,0,2.9,0,4.4,0
		c0.7,0,1.4,0.1,2.1,0.3c1.2,0.4,2.1,1.2,2.4,2.4c0.2,0.5,0.3,1,0.3,1.6c0,0.9,0,1.9,0,2.8c0,0.5-0.1,1-0.3,1.6
		c-0.3,0.8-0.7,1.5-1.4,1.9c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.1,0.2,0.2,0.2,0.2c0.7,0.4,1.2,1.1,1.4,1.8c0.2,0.5,0.3,1,0.3,1.5
		c0,1,0,2,0,3c0,0.6,0,1.2,0,1.8C144,101.2,144.1,101.8,144.3,102.4z M140.6,86.1c0-0.2-0.1-0.4-0.1-0.6c-0.2-0.7-0.6-1.1-1.4-1.1
		c-0.5,0-1,0-1.4,0c-0.3,0-0.3,0.1-0.3,0.4c0,1,0,1.9,0,2.9h0c0,0.7,0,1.3,0,2c0,0.3,0,0.6,0,0.9c0,0.2,0.1,0.3,0.2,0.3
		c0.5,0,1,0,1.4,0c0.8-0.1,1.3-0.5,1.5-1.2c0.1-0.2,0.1-0.4,0.1-0.6C140.6,88,140.6,87,140.6,86.1z M150.4,91.5
		c-1,2.5-2.1,5-3.1,7.5c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.8,0,1.5,0,2.3c0,0.3,0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.3,0c2.8,0,5.7,0,8.5,0
		c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.2c0-0.9,0-1.8,0-2.7c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4,0-0.6,0c-1.6,0-3.1,0-4.7,0
		c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.2,0.1-0.4,0.2-0.5c0.4-1,0.8-1.9,1.2-2.9c1.5-3.7,3.1-7.4,4.6-11
		c0.1-0.3,0.2-0.7,0.2-1c0-0.6,0-1.2,0-1.7c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.4,0c-2.7,0-5.4,0-8.1,0
		c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.6,0,1.2,0,1.9c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3,0,0.5,0
		c1.1,0,2.1,0,3.2,0h2.1c-0.1,0.3-0.2,0.5-0.3,0.8C152.1,87.2,151.3,89.4,150.4,91.5z M167.4,112.4c0-13.8,0-27.6,0-41.5
		c0-1,0.1-0.9-0.9-0.9c-9.6,0-19.1,0-28.7,0h-28.5c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.2
		c0,0.1,0.1,0.1,0.1,0.2c0.3,0,0.5,0,0.8,0c18.9,0,37.9,0,56.8,0c1,0,0.9-0.1,0.9,0.9c0,13.5,0,26.9,0,40.4c0,0.3,0.1,0.7-0.1,0.9
		c-0.2,0.2-0.6,0.1-0.9,0.1c-11.8,0-23.5,0-35.3,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0.1-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2
		c0.2,0,0.5,0,0.7,0c12,0,23.9,0,35.9,0C167.5,113.3,167.4,113.4,167.4,112.4z M144.3,102.4c-0.1,0-0.1,0.1-0.2,0.1
		c-1,0-1.9,0-2.9,0c-0.3,0-0.3,0-0.4-0.3c-0.2-0.5-0.2-1-0.2-1.6c0-1.4,0-2.7,0-4.1c0-0.4,0-0.8-0.1-1.2c-0.2-0.9-0.8-1.3-1.6-1.4
		c0,0-0.1,0-0.1,0c-0.4,0-0.9,0-1.3,0c-0.2,0.4-0.1,0.8-0.1,1.3c0,2.2,0,4.3,0,6.5c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.2-0.2,0.2
		c-0.9,0-1.9,0-2.8,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.3,0-0.4V82c0-0.8-0.1-0.7,0.7-0.7c1.5,0,2.9,0,4.4,0c0.7,0,1.4,0.1,2.1,0.3
		c1.2,0.4,2.1,1.2,2.4,2.4c0.2,0.5,0.3,1,0.3,1.6c0,0.9,0,1.9,0,2.8c0,0.5-0.1,1-0.3,1.6c-0.3,0.8-0.7,1.5-1.4,1.9
		c-0.1,0.1-0.2,0.1-0.3,0.2c0,0.1,0.2,0.2,0.2,0.2c0.7,0.4,1.2,1.1,1.4,1.8c0.2,0.5,0.3,1,0.3,1.5c0,1,0,2,0,3c0,0.6,0,1.2,0,1.8
		C144,101.2,144.1,101.8,144.3,102.4z M140.6,86.1c0-0.2-0.1-0.4-0.1-0.6c-0.2-0.7-0.6-1.1-1.4-1.1c-0.5,0-1,0-1.4,0
		c-0.3,0-0.3,0.1-0.3,0.4c0,1,0,1.9,0,2.9h0c0,0.7,0,1.3,0,2c0,0.3,0,0.6,0,0.9c0,0.2,0.1,0.3,0.2,0.3c0.5,0,1,0,1.4,0
		c0.8-0.1,1.3-0.5,1.5-1.2c0.1-0.2,0.1-0.4,0.1-0.6C140.6,88,140.6,87,140.6,86.1z M104.6,91.9v5.5c0,0.5,0,1.1-0.1,1.6
		c-0.5,2.1-1.8,3.3-3.9,3.6c-0.9,0.1-1.7,0.1-2.6-0.1c-1.5-0.4-2.6-1.3-3.1-2.8c-0.2-0.7-0.4-1.4-0.4-2.2c0-3.7,0-7.5,0-11.2
		c0-0.6,0.1-1.2,0.2-1.8c0.5-1.8,1.6-2.9,3.4-3.3c1-0.2,2.1-0.2,3.1,0.1c1.5,0.4,2.5,1.3,3,2.8c0.2,0.7,0.3,1.4,0.3,2.1
		C104.6,88.1,104.6,90,104.6,91.9z M101.3,91.9c0-1.7,0-3.5,0-5.2c0-0.3,0-0.7,0-1c-0.1-0.5-0.2-0.9-0.6-1.2c-1-0.7-2.5-0.1-2.7,1.1
		c-0.1,0.4-0.1,0.8-0.1,1.2c0,3.5,0,6.9,0,10.4c0,0.4,0,0.8,0.1,1.1c0.2,1.2,1.5,1.8,2.6,1.2c0.5-0.3,0.7-0.7,0.7-1.3
		c0-0.3,0-0.7,0-1C101.3,95.4,101.3,93.6,101.3,91.9z M68,102.5c-0.5,0-1,0-1.4,0v0c-0.5,0-0.9,0-1.4,0c-0.3,0-0.3-0.1-0.4-0.3
		c-0.1-0.7-0.2-1.4-0.3-2.1c-0.1-0.4-0.1-0.8-0.2-1.1c0-0.3-0.1-0.3-0.4-0.3c-1.1,0-2.3,0-3.4,0c-0.3,0-0.3,0-0.4,0.4
		c-0.2,1-0.3,2.1-0.5,3.1c0,0.3-0.1,0.3-0.4,0.3c-0.8,0-1.6,0-2.5,0c-0.2,0-0.3-0.1-0.3-0.3c0.3-1.8,0.6-3.6,0.9-5.4
		c0.3-1.7,0.5-3.3,0.8-5c0.3-2,0.6-3.9,0.9-5.9c0.2-1.3,0.4-2.7,0.6-4c0-0.1,0-0.2,0.1-0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0
		h3.9c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.3,0.3c0.1,0.6,0.2,1.2,0.3,1.8c0.6,3.6,1.2,7.2,1.7,10.8c0.4,2.6,0.8,5.2,1.2,7.8
		c0,0.1,0,0.1,0,0.2C68.2,102.4,68.1,102.5,68,102.5z M63.7,94.9c-0.3-1.8-0.5-3.6-0.8-5.3c-0.2-1.3-0.4-2.5-0.6-3.8
		c0,0-0.1,0-0.1,0c0,0.2-0.1,0.5-0.1,0.7c-0.3,1.8-0.5,3.6-0.8,5.3C61,92.9,60.9,94,60.7,95c0,0.2-0.1,0.5,0,0.7c1,0.1,2.5,0.1,3,0
		C63.8,95.4,63.7,95.2,63.7,94.9z M118.5,81.3c-0.8,0-1.7,0-2.5,0c-0.3,0-0.3,0.1-0.4,0.3c-0.1,0.8-0.2,1.5-0.3,2.3
		c-0.3,1.8-0.5,3.6-0.8,5.3c-0.3,1.8-0.5,3.7-0.8,5.5c-0.1,0.9-0.3,1.8-0.4,2.7c0,0.2-0.1,0.4-0.1,0.4c0,0-0.1-0.2-0.1-0.4
		c-0.2-1.1-0.3-2.3-0.5-3.4c-0.3-2.1-0.6-4.2-0.9-6.3c-0.3-2-0.6-4-0.9-6.1c0-0.3-0.1-0.3-0.4-0.3c-0.9,0-1.7,0-2.6,0
		c-0.1,0-0.3,0-0.4,0.1c0.1,0.6,0.2,1.3,0.3,1.9c0.3,1.8,0.5,3.5,0.8,5.3c0.3,1.7,0.5,3.4,0.8,5.1c0.3,2.1,0.6,4.1,1,6.2
		c0.1,0.8,0.2,1.5,0.4,2.3c0.1,0.3,0.1,0.4,0.4,0.4c1.4,0,2.8,0,4.3,0c0.3,0,0.4,0,0.4-0.4c0.1-0.7,0.2-1.4,0.3-2.1
		c0.3-1.7,0.5-3.4,0.8-5.1s0.5-3.4,0.8-5.1c0.3-1.8,0.5-3.5,0.8-5.3c0.2-1,0.3-2,0.5-3.1C118.6,81.4,118.5,81.3,118.5,81.3z
		 M125.2,84.4c0.2,0,0.3,0,0.5,0c1.4,0,2.7,0,4.1,0c0.2,0,0.4,0,0.6,0c0.1,0,0.2-0.1,0.2-0.2c0-0.3,0-0.6,0-0.9c0-0.6,0-1.1,0-1.7
		c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.1,0-0.2,0c-2.7,0-5.4,0-8.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0,0.6
		c0,3.2,0,6.5,0,9.7v9.7c0,0.2,0,0.4,0,0.6c0,0.3,0.1,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c2.6,0,5.2,0,7.9,0c0.1,0,0.3,0,0.4,0
		c0.1,0,0.2-0.1,0.2-0.2c0-0.9,0-1.8,0-2.7c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4,0-0.6,0c-1.4,0-2.8,0-4.2,0c-0.2,0-0.3,0-0.5,0
		c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0-0.5c0-1.3,0-2.6,0-3.9c0-0.5-0.1-1,0-1.4c0.5-0.2,1-0.1,1.5-0.1c0.8,0,1.7,0,2.5,0
		c0.5,0,0.5,0,0.5-0.5c0-0.7,0-1.3,0-2c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.5,0c-1,0-2.1,0-3.1,0
		c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0-0.3,0-0.5c0-1.1,0-2.3,0-3.4c0-0.5,0-1,0-1.4C124.9,84.4,125,84.4,125.2,84.4z
		 M54,99.8c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.5,0c-1.4,0-2.8,0-4.2,0c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.2-0.2
		c0-0.2,0-0.4,0-0.6c0-1.4,0-2.9,0-4.3c0-0.3-0.1-0.7,0-1c0.3-0.2,0.7-0.1,1.1-0.1c1,0,2.1,0,3.1,0c0.3,0,0.4,0,0.4-0.4
		c0-0.8,0-1.5,0-2.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3,0-0.5,0c-1.1,0-2.1,0-3.2,0c-0.2,0-0.3,0-0.5,0
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4c0-1.6,0-3.2,0-4.8c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.4,0,0.6,0
		c1.5,0,3,0,4.5,0c0.2,0,0.3-0.1,0.3-0.3c0-0.8,0-1.6,0-2.5c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.4,0c-2.5,0-5,0-7.6,0
		c-0.8,0-0.7-0.1-0.7,0.7c0,3.3,0,6.5,0,9.8v9.8c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3,0,0.5,0c2.5,0,5,0,7.5,0
		c0.1,0,0.3,0,0.4,0c0.3,0,0.4,0,0.4-0.3C54,101.4,54,100.6,54,99.8z M156.6,99.5c-0.2,0-0.4,0-0.6,0c-1.6,0-3.1,0-4.7,0
		c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.2,0.1-0.4,0.2-0.5c0.4-1,0.8-1.9,1.2-2.9c1.5-3.7,3.1-7.4,4.6-11
		c0.1-0.3,0.2-0.7,0.2-1c0-0.6,0-1.2,0-1.7c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.4,0c-2.7,0-5.4,0-8.1,0
		c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.6,0,1.2,0,1.9c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3,0,0.5,0
		c1.1,0,2.1,0,3.2,0h2.1c-0.1,0.3-0.2,0.5-0.3,0.8c-0.9,2.1-1.8,4.3-2.7,6.4c-1,2.5-2.1,5-3.1,7.5c-0.1,0.3-0.2,0.5-0.2,0.8
		c0,0.8,0,1.5,0,2.3c0,0.3,0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.3,0c2.8,0,5.7,0,8.5,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.2
		c0-0.9,0-1.8,0-2.7C156.8,99.5,156.7,99.5,156.6,99.5z M80.4,81.5c0,0,0-0.1-0.1-0.1c-0.2,0-0.3,0-0.5,0c-3,0-6.1,0-9.1,0
		c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.8,0,1.6,0,2.5c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.3,0,0.4,0
		c0.8,0,1.7,0,2.5,0c0.3,0,0.3,0,0.3,0.3c0,0.2,0,0.4,0,0.6v16.3c0,0.2,0,0.3,0,0.5c0,0.4,0,0.4,0.5,0.4c0.7,0,1.5,0,2.2,0
		c0.6,0,0.6,0,0.6-0.6c0-0.1,0-0.3,0-0.4V85.3c0-0.2,0-0.3,0-0.5c0-0.4,0-0.4,0.4-0.4c0.2,0,0.4,0,0.6,0c0.7,0,1.5,0,2.2,0
		c0.2,0,0.3-0.1,0.3-0.3C80.4,83.2,80.4,82.4,80.4,81.5z M92,99.7C92,99.7,92,99.6,92,99.7c-0.1-0.1-0.1-0.2-0.1-0.2
		c-0.2,0-0.4,0-0.6,0c-1.4,0-2.9,0-4.3,0c-0.4,0-0.4,0-0.4-0.4c0-0.2,0-0.3,0-0.5V82.3c0-0.2,0-0.3,0-0.5c0-0.5,0-0.5-0.5-0.5
		c-0.8,0-1.6,0-2.5,0c-0.4,0-0.4,0-0.4,0.4c0,0.1,0,0.3,0,0.4v9.7c0,3.3,0,6.5,0,9.8c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.2
		c0.1,0,0.3,0,0.4,0c2.5,0,5,0,7.4,0c0.1,0,0.3,0,0.4,0c0.2,0,0.3-0.1,0.3-0.3C92,101.4,92,100.5,92,99.7z M75.5,70.1
		c-0.2,0-0.5,0-0.7,0c-9.5,0-19.1,0-28.6,0s-19.1,0-28.6,0c-1,0-0.9-0.1-0.9,0.9c0,13.8,0,27.7,0,41.5c0,0.2,0,0.3,0,0.5
		c0,0.3,0.1,0.3,0.3,0.4c0.1,0,0.3,0,0.4,0h35.5c0.2,0,0.3,0,0.5,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.4,0-0.6,0
		c-11.6,0-23.2,0-34.8,0c-0.9,0-0.8,0.1-0.8-0.8c0-13.6,0-27.1,0-40.7c0-0.9-0.1-0.8,0.8-0.8c7.7,0,15.4,0,23.2,0
		c11.3,0,22.5,0,33.8,0c0.2,0,0.5,0,0.7,0c0.1,0,0.2-0.1,0.2-0.2C75.7,70.2,75.6,70.1,75.5,70.1z M167.4,112.4c0-13.8,0-27.6,0-41.5
		c0-1,0.1-0.9-0.9-0.9c-9.6,0-19.1,0-28.7,0h-28.5c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.2
		c0,0.1,0.1,0.1,0.1,0.2c0.3,0,0.5,0,0.8,0c18.9,0,37.9,0,56.8,0c1,0,0.9-0.1,0.9,0.9c0,13.5,0,26.9,0,40.4c0,0.3,0.1,0.7-0.1,0.9
		c-0.2,0.2-0.6,0.1-0.9,0.1c-11.8,0-23.5,0-35.3,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0.1-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2
		c0.2,0,0.5,0,0.7,0c12,0,23.9,0,35.9,0C167.5,113.3,167.4,113.4,167.4,112.4z M80.6,114.7c0-1.2,0-2.5,0-3.7c0-1.1,0.2-1-1-0.9
		c0,0-0.1,0-0.2,0.1c-0.2,1-0.3,2.1-0.5,3.1c-0.3-0.5-0.3-1-0.4-1.5c-0.1-0.5-0.2-1.1-0.3-1.6c-0.3-0.1-0.6,0-1,0
		c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0,0.3c0,1.4,0,2.9,0,4.3c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0.1,0.1c0.2,0.1,0.3,0.1,0.5,0
		c0.1,0,0.1-0.2,0.1-0.3c0-0.7,0-1.4,0-2.1c0.1-0.1,0-0.3,0.1-0.4c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.2c0,0,0,0,0-0.1
		c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.5,0.2,1,0.3,1.6
		c0.1,0.3,0.1,0.3,0.4,0.3c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.3-0.3c0.1-0.7,0.2-1.4,0.3-2.1c0.1-0.1,0.1-0.3,0.1-0.4
		c0.2,0.8,0,1.8,0.1,2.7c0.2,0.2,0.4,0.1,0.7,0.1C80.7,115.1,80.6,114.9,80.6,114.7z M99,114.6c-0.1,0.3-0.3,0.6-0.7,0.7
		c-0.5,0.1-1.1,0.1-1.6,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4c0-1.4,0-2.8,0-4.2c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.2-0.2
		c0.4,0,0.9,0,1.3,0c0.5,0,0.8,0.3,0.9,0.8c0.1,0.6,0.1,1.1-0.4,1.6C99.3,113.1,99.1,113.9,99,114.6z M97.4,111.4L97.4,111.4
		c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0.1,0.2c0.3,0.1,0.6-0.1,0.6-0.3c0.1-0.3,0.1-0.5,0-0.8c-0.1-0.2-0.3-0.3-0.6-0.3
		c-0.2,0-0.2,0.1-0.2,0.4C97.4,111.3,97.4,111.3,97.4,111.4z M98.2,113.3c0-0.3-0.2-0.4-0.6-0.4c-0.2,0-0.3,0.1-0.3,0.4
		c0,0.1,0,0.2,0,0.4c0,0,0,0,0,0c0,0.2,0,0.3,0,0.5c0,0.3,0.1,0.3,0.3,0.3c0.4,0,0.5-0.1,0.5-0.5C98.3,113.8,98.3,113.6,98.2,113.3z
		 M110.6,113.6c0,0.3,0,0.6-0.1,0.9c-0.1,0.4-0.3,0.6-0.7,0.8c-0.5,0.2-1.1,0.1-1.6,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4
		c0-1.4,0-2.8,0-4.2c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.2,0.2-0.2c0.5,0,1,0,1.4,0c0.4,0,0.8,0.3,0.8,0.8c0.1,0.6,0.1,1.2-0.4,1.6
		C110.5,112.8,110.6,113.2,110.6,113.6z M108.8,111.4L108.8,111.4c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.3,0.2c0.3,0,0.5-0.2,0.5-0.5
		c0-0.2,0-0.4,0-0.6c0-0.2-0.2-0.4-0.5-0.4c-0.2,0-0.2,0.1-0.2,0.4C108.8,111.2,108.8,111.3,108.8,111.4z M109.6,113.1
		c-0.1-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0,0.4h0c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.3,0.3
		c0.2,0,0.4,0.1,0.5-0.2C109.8,114,109.8,113.5,109.6,113.1z M106.6,115.3c-0.2,0.1-0.4,0.1-0.6,0c-0.1,0-0.1-0.1-0.1-0.2
		c0-0.3,0-0.6,0-0.9c0-0.2,0-0.4,0-0.6c0-0.2-0.2-0.4-0.4-0.4c-0.3,0-0.4,0-0.4,0.3c0,0.5,0,1.1,0,1.6c0,0.2-0.1,0.3-0.3,0.3
		c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.4c0-1.4,0-2.8,0-4.3c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.1,0.1-0.1
		c0.5,0,1,0,1.5,0c0.4,0,0.7,0.3,0.7,0.7c0.1,0.7,0.2,1.4-0.3,2c0.4,0.4,0.5,0.8,0.4,1.3c0,0.3,0,0.5,0,0.8
		C106.7,115,106.8,115.2,106.6,115.3z M105.8,111.2c0-0.3-0.2-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.3,0.2c0,0.2,0,0.4,0,0.6h0
		c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0.1,0.2c0.3,0.1,0.6-0.1,0.7-0.4C105.9,111.7,105.9,111.5,105.8,111.2z M121.4,110
		c-0.1,0-0.2,0-0.4,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.1,0,0.2,0,0.4c0,1,0,2.1,0,3.1c0,0.2,0,0.4,0,0.6c0,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.3-0.1-0.4-0.3c0-0.2,0-0.4,0-0.6c0-1.1,0-2.2,0-3.2c0-0.5,0-0.5-0.5-0.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1
		c0,0.1,0,0.2,0,0.3c0,1.2,0,2.4,0,3.5c0,0.2,0,0.3,0,0.5c0.1,0.4,0.3,0.7,0.7,0.8c0.8,0.2,1.7,0,1.7-1.2c0-0.1,0-0.1,0-0.2
		c0-1.2,0-2.3,0-3.5C121.5,110.3,121.5,110.2,121.4,110z M93.3,114.1c0,1-1,1.5-1.9,1.1c-0.3-0.2-0.5-0.4-0.6-0.8c0-0.2,0-0.4,0-0.6
		c0-0.8,0-1.6,0-2.4c0-0.1,0-0.1,0-0.2c0.1-1,0.8-1.5,1.8-1.2c0.4,0.2,0.7,0.5,0.7,1c0,0.4,0,0.9,0,1.3c0,0.1,0,0.2,0,0.4h0
		C93.3,113.2,93.3,113.7,93.3,114.1z M92.5,112.7L92.5,112.7c0-0.5,0-1.1,0-1.6c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.1-0.4,0.3
		c0,0.1,0,0.2,0,0.4c0,0.8,0,1.7,0,2.5c0,0.1,0,0.2,0,0.4c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.3-0.2,0.3-0.3c0-0.1,0-0.2,0-0.4
		C92.5,113.5,92.5,113.1,92.5,112.7z M103,115.2c0,0,0,0.1-0.1,0.1c-0.2,0-0.4,0.1-0.6,0c-0.1,0-0.1-0.2-0.1-0.2
		c-0.1-0.2,0-0.5-0.2-0.6c-0.2-0.2-0.5-0.1-0.8-0.1c0,0-0.1,0-0.2,0.1c0,0.2-0.1,0.4-0.1,0.5c0,0.1,0,0.3-0.1,0.3
		c-0.2,0.1-0.4,0.1-0.6,0c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.4,0.1-0.8,0.2-1.1c0.2-1.2,0.4-2.4,0.6-3.5c0.1-0.3,0.1-0.3,0.3-0.3
		c1.2,0,0.9-0.2,1.1,0.9c0.2,1.3,0.4,2.6,0.6,4C103,115,103,115.1,103,115.2z M101.9,113.3c-0.1-0.6-0.2-1.1-0.3-1.7
		c0,0-0.1,0-0.1,0c-0.2,0.6-0.2,1.2-0.3,1.8c0,0.2,0,0.3,0.2,0.3c0.1,0,0.1,0,0.2,0C101.9,113.6,102,113.6,101.9,113.3z
		 M117.8,113.5c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0.1-0.3,0.2c0,0.2,0,0.4,0,0.6c0,0.2-0.2,0.3-0.4,0.4
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0-0.4c0-0.8,0-1.6,0-2.5c0-0.1,0-0.2,0-0.4c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.3,0,0.4c0,0.3,0.1,0.3,0.3,0.3c0.5,0,0.6,0,0.5-0.6c0-0.2,0-0.3-0.1-0.5c-0.1-0.4-0.4-0.7-0.8-0.7c-0.2,0-0.5,0-0.7,0
		c-0.4,0.1-0.6,0.3-0.8,0.7c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.4,0,0.9,0,1.3c0,0.4,0,0.9,0,1.3c0,0.2,0,0.4,0.1,0.6
		c0.1,0.4,0.4,0.7,0.8,0.8c0.5,0.1,1,0.1,1.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3C117.8,114.3,117.8,113.9,117.8,113.5z M114.1,114.7
		c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7,0.1-0.9-0.1c-0.2-0.2,0-0.6-0.1-0.9c0-0.1,0-0.1,0-0.2
		c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.4,0,0.6,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1c0-0.4,0-0.4-0.4-0.4c0,0-0.1,0-0.1,0
		c-0.7,0-0.6,0-0.6-0.6c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.2,0.2-0.2c0.3,0,0.7,0,1,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3
		c0-0.2-0.1-0.3-0.3-0.3c-0.6,0-1.1,0-1.7,0c-0.3,0-0.3,0.1-0.3,0.4c0,0.8,0,1.5,0,2.3h0c0,0.8,0,1.6,0,2.4c0,0.2,0.1,0.3,0.3,0.3
		c0.6,0,1.2,0,1.7,0c0.2,0,0.3-0.1,0.3-0.3C114.1,114.9,114.1,114.8,114.1,114.7z M124.6,114.5c-0.3,0-0.5,0-0.8,0
		c-0.1,0-0.2-0.1-0.2-0.2c0-0.4,0-0.8,0-1.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5,0,0.7,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.4
		c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0.1-0.7-0.1c-0.2-0.2,0-0.5-0.1-0.7c0-0.1,0-0.2,0-0.4
		c0-0.2,0.1-0.3,0.3-0.3c0.3,0,0.6,0,0.9,0c0.2,0,0.2-0.1,0.2-0.3c0,0,0-0.1,0-0.1c0-0.3,0-0.3-0.3-0.3c-0.6,0-1.1,0-1.7,0
		c-0.2,0-0.3,0.1-0.3,0.3c0,0.8,0,1.5,0,2.3c0,0.8,0,1.6,0,2.4c0,0.2,0,0.3,0.2,0.3c0.6,0,1.2,0,1.9,0c0.1,0,0.2-0.1,0.2-0.2
		c0,0,0-0.1,0-0.1C125.1,114.6,125,114.5,124.6,114.5z M84.2,114.7c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.2-1-0.1
		c-0.2-0.3,0-0.6-0.1-1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0-0.2,0-0.3
		c0-0.2-0.1-0.3-0.3-0.3c-0.3,0-0.6,0.1-0.8-0.1c-0.2-0.2,0-0.6-0.1-0.8c0,0,0-0.1,0-0.1c0-0.3,0-0.3,0.3-0.3c0.3,0,0.6,0,0.9,0
		c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2c0-0.3,0-0.3-0.3-0.3c-0.6,0-1.1,0-1.7,0c-0.3,0-0.3,0.1-0.3,0.3c0,0.8,0,1.5,0,2.3
		c0,0.8,0,1.5,0,2.3c0,0.3,0,0.4,0.3,0.4c0.6,0,1.1,0,1.7,0c0.2,0,0.3-0.1,0.3-0.3C84.2,114.9,84.2,114.8,84.2,114.7z M67.9,112.9
		c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.2-0.2-0.5-0.2-0.7c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.3,0.1,0.4,0.3
		c0,0.1,0,0.1,0,0.2c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.2-0.2c0-0.8-0.4-1.3-1.3-1.3c-0.7,0-1.1,0.4-1.2,1.1
		c-0.1,0.5,0.1,1,0.4,1.4c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.2,0.2,0.4,0.2,0.7c0,0.2-0.1,0.4-0.3,0.4
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.1-0.6,0-0.6,0.5c0,0.1,0,0.2,0,0.3
		c0.1,0.6,0.6,0.9,1.2,0.9c0.7,0,1.1-0.3,1.2-0.9C68.4,113.9,68.3,113.3,67.9,112.9z M89.4,110c-0.6,0-1.2,0-1.7,0
		c-0.5,0-0.4,0.1-0.4,0.4c0,0.3,0,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c0.3,0,0.3,0.1,0.3,0.4c0,0.6,0,1.2,0,1.9h0c0,0.6,0,1.1,0,1.7
		c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.4,0c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.3,0-0.5c0-1.1,0-2.2,0-3.2
		c0-0.5,0-0.6,0.5-0.6c0,0,0,0,0.1,0c0.2,0,0.3-0.1,0.3-0.3C89.8,110.1,89.8,110,89.4,110z M62.8,110.3c0-0.2-0.1-0.3-0.3-0.3
		c-0.7,0-1.4,0-2.1,0c-0.1,0-0.2,0-0.2,0.2c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.4,0c0.3,0,0.4,0.1,0.4,0.4
		c0,0.6,0,1.2,0,1.9h0c0,0.7,0,1.3,0,2c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.1,0,0.2,0c0.3,0,0.3,0,0.4-0.3c0-0.2,0-0.3,0-0.5
		c0-1,0-1.9,0-2.9c0-0.3-0.1-0.6,0.1-0.8c0.1-0.2,0.4,0,0.6-0.1c0.1,0,0.2-0.1,0.2-0.2C62.8,110.5,62.8,110.4,62.8,110.3z M73.5,110
		c-0.6,0-1.2,0-1.8,0c-0.4,0-0.4,0-0.4,0.4c0,0.3,0,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.3,0.3c0,0.6,0,1.3,0,1.9h0
		c0,0.6,0,1.2,0,1.7c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1c0.2,0,0.4,0,0.6,0c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.3,0-0.4
		c0-1.1,0-2.1,0-3.2c0-0.2-0.1-0.5,0.1-0.7c0.2-0.2,0.4-0.1,0.6-0.1c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1
		C73.8,110.1,73.8,110,73.5,110z M59.1,110.5c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.2-0.3-0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.4,0-0.4,0.4
		c0,0.7,0,1.5,0,2.2c0,0.7,0,1.4,0,2.2c0,0.5,0,0.6,0.6,0.5c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3
		C59.1,113.4,59.1,111.9,59.1,110.5z M75.8,114.9c0-1.5,0-2.9,0-4.4c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.3,0-0.4,0
		c-0.2,0-0.3,0.1-0.3,0.3v2.4h0c0,0.7,0,1.4,0,2.1c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1c0.2,0,0.4,0,0.6,0c0,0,0.1-0.1,0.1-0.2
		C75.8,115.1,75.8,115,75.8,114.9z M38,88.2C38,88.2,38,88.2,38,88.2L38,88.2C38,88.2,38,88.2,38,88.2z M64.7,110.2
		c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0-0.2,0c-0.4,0-0.5,0-0.5,0.4c0,0.2,0,0.3,0.2,0.4c0.1,0.1,0.1,0.1,0.1,0.2
		c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2c0.1,0,0.2,0,0.2-0.1C64.6,111.1,64.8,110.7,64.7,110.2z M79.8,111.8
		c-0.1,0.5-0.3,0.8-0.2,1.2c0.2,0.1,0.2,0.2,0.2,0.4c0,0.5,0,1,0,1.5c0,0.2,0,0.3,0.1,0.5C80,114,79.9,112.5,79.8,111.8z M78,112.5
		C78,112.5,78,112.4,78,112.5C77.9,112.4,77.9,112.4,78,112.5c-0.1-0.1-0.1,0-0.1,0c0,0.1,0,0.3,0,0.4c0,0,0.1,0,0.1,0
		C78,112.8,78,112.6,78,112.5z M38,88.2C38,88.2,38,88.2,38,88.2L38,88.2C38,88.2,38,88.2,38,88.2z M62.1,85.8c0,0,0.1,0,0.1,0
		C62.2,85.7,62.2,85.7,62.1,85.8z M62.3,85.5C62.3,85.5,62.2,85.5,62.3,85.5c-0.1,0-0.1,0-0.1,0c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.1,0
		C62.3,85.7,62.3,85.6,62.3,85.5z M78,112.2C78,112.2,77.9,112.2,78,112.2c-0.1-0.1-0.1,0-0.1,0c0,0.1,0,0.2,0,0.3c0,0,0.1,0,0.1,0
		C78,112.4,78,112.3,78,112.2z M101.5,111.6c0,0,0.1,0,0.1,0C101.6,111.6,101.6,111.5,101.5,111.6z M101.6,111.3
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0.1,0,0.1,0C101.7,111.5,101.7,111.4,101.6,111.3z"/>
</g>
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>